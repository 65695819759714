import {EventBus} from "../../EventBus";

export class SocialLinksContainer extends Phaser.GameObjects.Container {

    readonly iconWidth: number
    readonly gapWidth: number

    private readonly whitepaper: Phaser.GameObjects.Image;
    private readonly info: Phaser.GameObjects.Image;
    private readonly discord: Phaser.GameObjects.Image;
    private readonly twitter: Phaser.GameObjects.Image;
    private readonly website: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y);

        this.whitepaper = scene.add.image(0, 0, 'button-whitepaper').setOrigin(0, 0).setInteractive({cursor: 'pointer'});
        this.iconWidth = this.whitepaper.width;
        this.gapWidth = this.iconWidth * 0.3;
        this.info = scene.add.image(this.iconWidth + this.gapWidth, 0, 'button-info').setOrigin(0, 0).setInteractive({cursor: 'pointer'});
        this.discord = scene.add.image((this.iconWidth + (this.iconWidth * 0.3)) * 2, 0, 'button-discord').setOrigin(0, 0).setInteractive({cursor: 'pointer'});
        this.twitter = scene.add.image((this.iconWidth + (this.iconWidth * 0.3)) * 3, 0, 'button-twitter').setOrigin(0, 0).setInteractive({cursor: 'pointer'});
        this.website = scene.add.image((this.iconWidth + (this.iconWidth * 0.3)) * 4, 0, 'button-website').setOrigin(0, 0).setInteractive({cursor: 'pointer'});

        this.add(this.whitepaper);
        this.add(this.info);
        this.add(this.discord);
        this.add(this.twitter);
        this.add(this.website);

        this.setOpenLink(this.whitepaper, 'https://whitepaper.brigadegame.io/');
        this.setOpenLink(this.discord, 'https://discord.gg/tacocrypto');
        this.setOpenLink(this.twitter, 'https://twitter.com/tacowax');
        this.setOpenLink(this.website, 'https://tacostudios.io/');

        scene.add.existing(this);
        this.setOverEffect(this.whitepaper);
        this.setOverEffect(this.info);
        this.setOverEffect(this.discord);
        this.setOverEffect(this.twitter);
        this.setOverEffect(this.website);

        this.info.on('pointerup', () => {
            EventBus.emit('open-tutorial');
        }, this);

    }

    private setOpenLink(image: Phaser.GameObjects.Image, url: string) {
        image.setInteractive({cursor: 'pointer'});
        image.on('pointerup', () => {
            window.open(url, '_blank')
        }, this);
    }

    setOverEffect(target: Phaser.GameObjects.Image) {
        const y = target.y;
        target.on('pointerover', () => {
            this.scene.add.tween({
                targets: [target],
                y: y - 10,
                duration: 300
            });
        });
        target.on('pointerout', () => {
            this.scene.add.tween({
                targets: [target],
                y: y,
                duration: 300
            });
        });
    }

    getGeneratedWidth(): number {
        return ((this.iconWidth + this.gapWidth) * 5) - this.gapWidth;
    }

}
